<template>
    <div class="dF topbar relative jSB px-3" style="box-shadow: 0px 8px 28px rgba(100, 100, 100, 0.1); background: white; z-index:10">
        <a-modal
            v-model="exitModal"
            title="Exit Builder"
            :centered="true"
            @cancel="closeModal"
        >
            Save Project before exiting?
            <template slot="footer">
                <a-button key="back" @click="dontSave">
                    Don't Save
                </a-button>
                <a-button key="submit" type="primary" @click="saveAndClose" :loading="currentlySaving">
                    Save and Close
                </a-button>
            </template>
        </a-modal>
        <div class="dF">
            <img src="@/assets/logo.svg" style="max-height:40px;" class="cursor-pointer" @click="exit" />
        </div>
        <div  class="dF jC text-center" style="gap:2ch;" v-if="false">
           
            <div @click="size.current = 'mobile'" class="topButtons" style="font-size:15px" :class="size.current === 'mobile'? 'text-primary' : ''">
                <svg style="fill:currentColor" xmlns="http://www.w3.org/2000/svg" width="10.813" height="17.301" viewBox="0 0 10.813 17.301"><path d="M9.191,0H1.622A1.622,1.622,0,0,0,0,1.622V15.679A1.622,1.622,0,0,0,1.622,17.3H9.191a1.622,1.622,0,0,0,1.622-1.622V1.622A1.622,1.622,0,0,0,9.191,0ZM5.406,16.219a1.081,1.081,0,1,1,1.081-1.081A1.08,1.08,0,0,1,5.406,16.219ZM9.191,12.57a.407.407,0,0,1-.405.405H2.027a.407.407,0,0,1-.405-.405V2.027a.407.407,0,0,1,.405-.405H8.786a.407.407,0,0,1,.405.405Z"/></svg>
            </div>
            <div @click="size.current = 'desktop'" class="topButtons" :class="size.current === 'desktop'? 'text-primary' : ''">
                <svg style="fill:currentColor" xmlns="http://www.w3.org/2000/svg" width="19.463" height="17.301" viewBox="0 0 19.463 17.301"><path d="M17.841,0H1.622A1.622,1.622,0,0,0,0,1.622V12.435a1.622,1.622,0,0,0,1.622,1.622H8.11l-.541,1.622H5.136a.811.811,0,0,0,0,1.622h9.191a.811.811,0,0,0,0-1.622H11.894l-.541-1.622h6.488a1.622,1.622,0,0,0,1.622-1.622V1.622A1.622,1.622,0,0,0,17.841,0ZM17.3,11.894H2.163V2.163H17.3Z" /></svg>
            </div>
        </div>
        <div  style="align-items:center;" v-if="mode === 'none'">
			<a-tag v-if="false" class="mr-3" :color="`var(--success)`">Connected</a-tag>
            <a-tag v-else class="mr-3" :color="`var(--danger)`">Not Connected</a-tag>
			<a-button class="mr-3" style="background: transparent;" @click="connectDevice()" icon="link">CONNECT TO DEVICE</a-button>
            <a-button class="mr-3" style="background: transparent;" @click="previewTouch()" icon="cloud">PUBLISH</a-button>
            <a-button class="mr-3" @click="$emit('save')" type="primary">SAVE</a-button>
            <a-icon v-if="$p > 10" type="close" @click="exit" class="close-button" />
            <a-icon v-else type="close" @click="$emit('close',false)" class="close-button" />
        </div>
        <div  style="align-items:center;" v-if="mode === 'preview'">
            <a-button class="mr-3" type="primary" @click="$emit('preview')" icon="close">EXIT PREVIEW</a-button>
        </div>
        
        
    </div>
</template>

<script>
import {validateEmail} from 'bh-mod'
export default {
    props:['size','mode','currentlySaving'],
    data:() => ({
        sendTestBoolean:false,
        templateName:'',
        emails:'',
        exitModal:false
    }),
    methods:{
		connectDevice(){
			window.open(`https://domain.bildhive.${this.$tld}`, '_blank').focus();
		},
        // exit(){
        //     let self = this
        //     this.$confirm({
        //         title: "Exit Builder",
        //         content: h => <div>Save Project before exiting?</div>,
        //         okText: 'Save and Exit',
        //         okType: `success`,
        //         cancelText: `Don't Save`,
        //         centered: true,
        //         onOk:() => {
        //             self.$emit('close')
        //         },
        //         onCancel() {
        //             self.$emit('close',false)
        //         }
        //     })
            
        // },
		previewTouch(){
			if (this.$p < 40) {
                return this.$message.error('You do not have permission to publish this project')
            }
			this.$emit('preview')
		},
        closeModal(){
            this.exitModal = false;
        },
        exit(){
            this.exitModal = true;
        },
        saveAndClose(){
			if (this.$p < 20) {
                return this.$message.error('You do not have permission to save this project')
            }
            this.$emit('close')
        },
        dontSave(){
            this.$emit('close',false)
        }
    }
  
}
</script>


<style lang="scss">
    .topIcon{
        fill: none;
        stroke: #3f3356;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 2px;
    }
    .topButtons:hover{
        background-color: #FAF9F9;
        cursor: pointer;
        transition: background-color .1s ease-in-out;
    }
    .topbar {
        background: #FFF;
        width:100%;
        height:62px;
        border-bottom: 1px solid grey;
        border-color:transparent;
        padding: rem(15) rem(20);
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: nowrap;
        box-shadow:rgba(100, 100, 100, 0.2) 0px 13px 32px;
    }
    .topButtons:hover{
        background-color: #FAF9F9;
        cursor: pointer;
        transition: background-color .1s ease-in-out;
    }
</style>