export const sections = [
    {
      name:'Header',
      id:'kjh123',
      settings:[

          {
            "id": "headerFont",
            "type": "font",
            "label": "Font for Header",
            "default": "Hello world"
          },
          {
            "id": "header",
            "type": "text",
            "label": "Header",
            "default": "Hello world"
          }
      ],
      data:{
        header:'Hello'
      },
      template:`
      <mj-section css-class="section-container section-id-{{id}}" id="hello" padding="20px 0 0 0">
        <mj-column>
        <mj-image src="http://nimus.de/share/tpl-realestate/box-head.png" alt="" align="center" border="none" padding="0px"></mj-image>
        </mj-column>
      </mj-section>
      <mj-section css-class="section-container section-id-{{id}}" id="hello2" name="jhjkhjkhjkj hello" padding-top="40px" background-color="#FFFFFF">
        <mj-column width="570px">
        <mj-text align="left" padding="0 20px" color="#1D83A8" font-size="36px" line-height="40px" font-family="{{ headerFont | default: _global.fonts.primary.fontText }}">{{header}}</mj-text>
        <mj-text align="left" padding="0 20px"><strong><span style="font-size: 40px;">⚊</span></strong><br /><br /></mj-text>
        </mj-column>
      </mj-section>`
    },
    {
      name:'Second Widget',
      id:'kjh123xxx',
      settings:[
        {
          id:'bodyText',
          type:'richtext',
          label:'Body Text',
          default: "<strong>Aliquam lorem ante, dapibus in hasellus viverra nulla</strong> ut metus varius laoreet. Quisque rutrum lorem dellorus. Aenean imperdiet.",
        }
      ],
      data:{
        bodyText:'<strong>Aliquam lorem ante, dapibus in hasellus viverra nulla</strong> ut metus varius laoreet. Quisque rutrum lorem dellorus. Aenean imperdiet.',
      },
      template:`
      <mj-section css-class="section-container section-id-{{id}}" background-color="#FFFFFF">
        <mj-column width="300px">
          <mj-text align="left" padding="0 20px 30px 20px">{{bodyText}}</mj-text>
        </mj-column>
        <mj-column width="260px">
          <mj-image src="http://nimus.de/share/tpl-realestate/icon-1.png" alt="" padding-bottom="30px" width="260px" align="center" border="none"></mj-image>
        </mj-column>
      </mj-section>`
    },
    {
      name:'Hero Widget',
      id:'modelidlkjsdlkfjsfd',
      settings:[
          {
            "id": "bg",
            "type": "image",
            "label": "Background Image",
            "default": "http://nimus.de/share/tpl-realestate/img-1.png"
          },
          {
            "id": "header",
            "type": "text",
            "label": "Header",
            "default": "Hello world"
          }
      ],
      data:{
        bg:'http://nimus.de/share/tpl-realestate/img-1.png',
        name:'Yellow',
        price:'12312312',
      },
      template:`<mj-section css-class="section-container section-id-{{id}}" background-url="{{bg}}" vertical-align="middle" background-size="cover" padding="30px" background-repeat="no-repeat">
        <mj-column vertical-align="middle" width="60%">
        <mj-text align="center" padding="40px 30px" color="#FFFFFF" font-size="42px" line-height="48px" font-family="{{_global.fonts.primary.fontText}}">Villa Semperin</mj-text>
        </mj-column>
        <mj-column vertical-align="middle" width="40%" background-color="#FFFFFF">
        <mj-image src="http://nimus.de/share/tpl-realestate/box-top.png" width="220px" alt="" align="center" border="none"></mj-image>
        <mj-text align="center" padding="20px 20px 0 20px" color="#1D83A8" font-size="26px" line-height="30px" font-family="{{_global.fonts.primary.fontText}}">– {{name}} –</mj-text>
        <mj-text align="center" padding="10px 20px" font-size="22px" line-height="30px">{{price | currency }}</mj-text>
        <mj-text align="center" padding="0 20px">Nascetur ridiculus mus. Donec quam felis, ultricies nec</mj-text>
        <mj-button background-color="#F44E3C" color="white" padding="20px 20px 30px 20px" border-radius="20px">view details</mj-button>
        </mj-column>
      </mj-section>`,

    },
    {
      name:'Carousel Widget',
      id:'carousel-widget_uidid',
      settings:[
        {
          "id": "gallery",
          "type": "images",
          "label": "Slider Images",
          "default": [
            'https://www.mailjet.com/wp-content/uploads/2016/11/ecommerce-guide.jpg',
            'https://www.mailjet.com/wp-content/uploads/2016/09/3@1x.png',
            'https://www.mailjet.com/wp-content/uploads/2016/09/1@1x.png'

          ]
        },
      ],
      data:{
        gallery:[
          'https://www.mailjet.com/wp-content/uploads/2016/11/ecommerce-guide.jpg',
          'https://www.mailjet.com/wp-content/uploads/2016/09/3@1x.png',
          'https://www.mailjet.com/wp-content/uploads/2016/09/1@1x.png'
        ]
      },
      template:`
        <mj-section css-class="section-container section-id-{{id}}" background-url="{{bg}}">
          <mj-column>
            <mj-carousel>
              {% if gallery %}
                {% for img in gallery  %}
                  <mj-carousel-image src="{{img}}" />
                {% endfor %}
              {% endif %}
            </mj-carousel>
          </mj-column>
        </mj-section>
      `
    },
    {
      id:'navbar-lksjdf',
      name:'Navbar',
      settings:[
        {
          "id": "navBg",
          "type": "color",
          "label": "Nav Background Color",
          "default": "Hello world"
        },
        {
          "id": "navColor",
          "type": "color",
          "label": "Nav Link Color",
          "default": "#ffffff"
        },
        {
          "id": "navs",
          "type": "navs",
          "label": "Nav List",
          "default": [
            {link:'https://www.countrywidehomes.ca/home',label:'Home'},
            {link:'https://www.countrywidehomes.ca/about',label:'About us'},
            {link:'https://www.countrywidehomes.ca/register',label:'Register Now'},
          ]
        },

      ],
      data:{
        navBg:'#ef6451',
        navColor:'#ffffff',
        navs:[
          {link:'https://www.countrywidehomes.ca/home',label:'Home'},
          {link:'https://www.countrywidehomes.ca/about',label:'About us'},
          {link:'https://www.countrywidehomes.ca/register',label:'Register Now'},
        ]
      },
      template:`<mj-section css-class="section-container section-id-{{id}}" background-color="{{navBg}}" padding="20px">
        <mj-column>
          <mj-navbar hamburger="hamburger" ico-color="#ffffff">

            {% if navs %}
              {% for nav in navs  %}
                <mj-navbar-link padding="0 35px" font-weight="bold" font-size="12px" href="{{nav.link}}" color="{{navColor}}"> {{nav.label}} </mj-navbar-link>
              {% endfor %}
            {% endif %}

          </mj-navbar>
        </mj-column>
      </mj-section>`
    },
    {
      name:'Real estate',
      id:'djfkrelix',
      settings:[
        {
            "id": "query",
            "type": "text",
            "label": "Search Query",
            "default": "Hello world"
          }
      ],
      data:{
        query:'toron'
      },
      template:`{% assign listings = query | rly %}
         {% for mls in listings  %}

            {% capture thecycle %}{% cycle 'odd', 'even' %}{% endcapture %}

            {% if thecycle == 'odd' %}

              <mj-section css-class="section-container section-id-{{id}}" padding="30px" background-color="#FFFFFF">
                <mj-column width="60%">

                  <mj-text align="center" padding-bottom="20px" font-size="26px" line-height="30px" font-family="{{_global.fonts.primary.fontText}}">
                    {{mls.address}}
                  </mj-text>
                  <mj-text align="center" padding="0 40px">asdfasdf lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Lorem Ipsum</mj-text>
                  <mj-image src="http://nimus.de/share/tpl-realestate/icon-2.png" alt="" padding="30px 0 10px 0" width="50px" align="center" border="none"></mj-image>
                </mj-column>

                <mj-column width="40%" background-color="#FFFFFF">
                  <mj-image src="https://cdn.bildhive.com/Models/6e6c8756c80d43828e694d43bce48615.jpg" width="216px" alt="" align="center" border="none"></mj-image>
                </mj-column>

              </mj-section>

              <mj-section css-class="section-container section-id-{{id}}"  padding="20px" background-color="#FFFFFF">
                <mj-column width="100%">
                  <mj-image src="http://nimus.de/share/tpl-realestate/symbol-2.png" alt="" width="213px" align="right" border="none"></mj-image>
                </mj-column>
              </mj-section>


            {% else %}

              <mj-section css-class="section-id-{{id}}"  padding="0 30px 30px" background-color="#FFFFFF">

                <mj-column width="55%">
                  <mj-image src="https://cdn.bildhive.com/Models/6e6c8756c80d43828e694d43bce48615.jpg" width="280px" padding-bottom="20px" alt="" align="center" border="none"></mj-image>
                </mj-column>

                <mj-column width="45%" background-color="#FFFFFF">
                  <mj-text align="center" padding-bottom="20px" font-size="26px" line-height="30px" font-family="{{_global.fonts.primary.fontText}}">{{mls.address}}</mj-text>
                  <mj-text align="center" padding="0 40px">Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum.</mj-text>
                  <mj-image src="http://nimus.de/share/tpl-realestate/icon-3.png" alt="" padding="30px 0 10px 0" width="50px" align="center" border="none"></mj-image>
                </mj-column>

              </mj-section>

              <mj-section css-class="section-id-{{id}}"  padding="20px" background-color="#FFFFFF">
                <mj-column width="100%">
                  <mj-image src="http://nimus.de/share/tpl-realestate/symbol-1.png" alt="" width="292px" align="left" border="none"></mj-image>
                </mj-column>
              </mj-section>

            {% endif %}





         {% endfor  %}

      `
    },
    {
      name:'Model Thing',
      id:'mmodelkjh123xxx',
      settings:[],
      data:{
        section:{
          models:`https://api.bildhive.dev/v1/models?token=5e60164253b01f74b658af20`,
        }
      },
      template:`{% if section.models %}

        {% assign models = section.models | api | test %}

        {% if models.unitGroups %}
          {% assign modelIndex = 0 %}
          {% assign modelsLen = models.unitGroups.size  %}

          {% for model in models.unitGroups  %}

            {% assign modelIndex = modelIndex | plus: 1 %}
            {% capture thecycle %}{% cycle 'odd', 'even' %}{% endcapture %}

            {% if thecycle == 'odd' %}

              <mj-section css-class="section-container section-id-{{id}}" padding="30px" background-color="#FFFFFF">
                <mj-column width="60%">

                  <mj-text align="center" padding-bottom="20px" font-size="26px" line-height="30px" font-family="{{_global.fonts.primary.fontText}}">
                    {{model.name}}
                  </mj-text>
                  <mj-text align="center" padding="0 40px">asdfasdf lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Lorem Ipsum</mj-text>
                  <mj-image src="http://nimus.de/share/tpl-realestate/icon-2.png" alt="" padding="30px 0 10px 0" width="50px" align="center" border="none"></mj-image>
                </mj-column>

                <mj-column width="40%" background-color="#FFFFFF">
                  <mj-image src="{{model.image}}" width="216px" alt="" align="center" border="none"></mj-image>
                </mj-column>

              </mj-section>

              <mj-section css-class="section-container section-id-{{id}}"  padding="20px" background-color="#FFFFFF">
                <mj-column width="100%">
                  <mj-image src="http://nimus.de/share/tpl-realestate/symbol-2.png" alt="" width="213px" align="right" border="none"></mj-image>
                </mj-column>
              </mj-section>


            {% else %}

              <mj-section css-class="section-id-{{id}}"  padding="0 30px 30px" background-color="#FFFFFF">

                <mj-column width="55%">
                  <mj-image src="{{model.image}}" width="280px" padding-bottom="20px" alt="" align="center" border="none"></mj-image>
                </mj-column>

                <mj-column width="45%" background-color="#FFFFFF">
                  <mj-text align="center" padding-bottom="20px" font-size="26px" line-height="30px" font-family="{{_global.fonts.primary.fontText}}">{{model.name}}</mj-text>
                  <mj-text align="center" padding="0 40px">Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum.</mj-text>
                  <mj-image src="http://nimus.de/share/tpl-realestate/icon-3.png" alt="" padding="30px 0 10px 0" width="50px" align="center" border="none"></mj-image>
                </mj-column>

              </mj-section>

              <mj-section css-class="section-id-{{id}}"  padding="20px" background-color="#FFFFFF">
                <mj-column width="100%">
                  <mj-image src="http://nimus.de/share/tpl-realestate/symbol-1.png" alt="" width="292px" align="left" border="none"></mj-image>
                </mj-column>
              </mj-section>

            {% endif %}

          {% endfor %}
        {% else %}
          <h1>No Models</h1>
        {% endif %}

      {% endif %}`,
    },
    {
      name:'Footer Style 1',
      id:'moomsdfooterid',
      settings:[],
      data:{},
      template:`
            <mj-wrapper full-width="full-width">
              <mj-section>
                <mj-column width="100%" padding="0">
                  <mj-social font-size="15px" icon-size="30px" mode="horizontal" padding="0" align="center">
                    <mj-social-element name="facebook" href="https://mjml.io/" background-color="#A1A0A0">
                    </mj-social-element>
                    <mj-social-element name="google" href="https://mjml.io/" background-color="#A1A0A0">
                    </mj-social-element>
                    <mj-social-element name="twitter" href="https://mjml.io/" background-color="#A1A0A0">
                    </mj-social-element>
                    <mj-social-element name="linkedin" href="https://mjml.io/" background-color="#A1A0A0">
                    </mj-social-element>
                  </mj-social>
                  <mj-text color="#445566" font-size="11px" font-weight="bold" align="center">
                    View this email in your browser
                  </mj-text>
                  <mj-text color="#445566" font-size="11px" align="center" line-height="16px">
                    You are receiving this email advertisement because you registered with Croft's Accountants. (123 Main Street, Austin, TX 78701) and agreed to receive emails from us regarding new features, events and special offers.
                  </mj-text>
                  <mj-text color="#445566" font-size="11px" align="center" line-height="16px">
                    &copy; Croft's Accountants Inc., All Rights Reserved.
                  </mj-text>
                </mj-column>
              </mj-section>
              <mj-section padding-top="0">
                <mj-group>
                  <mj-column width="100%" padding-right="0">
                    <mj-text color="#445566" font-size="11px" align="center" line-height="16px" font-weight="bold">
                      <a class="footer-link" href="https://www.google.com">Privacy</a>&#xA0;&#xA0;&#xA0;&#xA0;&#xA0;&#xA0;&#xA0;&#xA0;<a class="footer-link" href="https://www.google.com">Unsubscribe</a>
                    </mj-text>
                  </mj-column>
                </mj-group>

              </mj-section>
            </mj-wrapper>
      `,

    }
  ]
