import axios from 'axios'
import { Liquid } from 'liquidjs';

var engineOriginal = new Liquid();



const renderUniqueString = (arr,template) => {

    if (!Array.isArray(arr)) return ''
    let keys = []

    return arr.map(x => x.trim().toLowerCase()).filter(e => {
        let found = keys.find(x => x === e)
        if (found)	return false 
        return keys.push(e),true
    }).map( e =>{
        return template.replace('{str}', e)
    }).join('')

}



const apiGet = url => {
	return new Promise( res => {
		axios.get(url).then( ({data}) => res(data)).catch( () => res(''))
	})
}

const rlyGet = (val = 'toro') => {
	return new Promise( res => {
		axios
        .post("https://api.realiya.com/v1", {
          operation: "autocomplete",
          value: val,
        })
        .then(({ data }) => {
          if (data.address) {
			return res(data.address.filter((x, xI) => xI < 5))
          }
        //   if (data.address) {
        //     this.locations = data.address.filter((x, xI) => xI < 5);
        //   }
        })

	})
}

engineOriginal.registerFilter('api', async e => {
	return await apiGet(e)
})

engineOriginal.registerFilter('rly', async e => {
	return await rlyGet(e)
})

engineOriginal.registerFilter('currency', async e => {
	let val = e
	if (typeof e === 'string') val = parseFloat(e)
	if (isNaN(val)) return e
	return '$' + val.toLocaleString('US')
})


// engineOriginal.registerFilter('stringify', async e => {
// 	console.log('EEEEEEEEEEEE TESTING')
// 	return JSON.stringify(e)
// })




engineOriginal.registerTag('upper', {
    parse: function(tagToken, remainTokens) {
        this.str = tagToken.args;
    },
    render: async function(scope, hash) {
        var str = await this.liquid.evalValue(this.str, scope); 
        return str.toUpperCase()  
    }
})





engineOriginal.registerTag('links', {
    parse: function(tagToken, remainTokens) {
		this.str = tagToken.args;
    },
    render: async function(scope, hash) {
		var arr = await this.liquid.evalValue(this.str, scope)
		return renderUniqueString(arr,`<link rel="stylesheet" href="{str}" />`)
    }
})






engineOriginal.registerTag('scripts', {
    parse: function(tagToken, remainTokens) {
		this.str = tagToken.args;
    },
    render: async function(scope, hash) {
		var arr = await this.liquid.evalValue(this.str, scope)
		return renderUniqueString(arr,`<script src="{str}"></script>`)
    }
})





engineOriginal.registerTag('stringify', {
    parse: function(tagToken, remainTokens) {
		this.str = tagToken.args;
    },
    render: async function(scope, hash) {
		var obj = await this.liquid.evalValue(this.str, scope)
		if (!obj) return ''
		return JSON.stringify(obj)
    }
})







engineOriginal.registerTag('set', {
    parse: function(tagToken, remainTokens) {
		this.str = tagToken.args;
		console.log('THIS STR', tagToken.args)
    },
    render: async function(scope, hash) {
	
		var arr = await this.liquid.evalValue(this.str, scope); 
		console.log('STRRRRRR',arr)
		
		return arr.join('')
        return str.toUpperCase()  
    }
})


export const engine = engineOriginal