<template>
    <div class="bg-white px-3 pb-3">
        <a-form>
            <template v-for="(item,itemI) in translations">
                <h5 v-if="item.type == 'header'" v-text="item.label" :key="itemI + 'lang'" class="pt-3"/>
                <a-form-item v-else :key="itemI + 'lang'" :label="item.label" class="mb-1">
                    <a-input v-model="translated[item.id]" />
                </a-form-item>            
            </template>
            
        </a-form>
        <div class="mt-3">
            <a-button type="primary" @click="update">Update Translation</a-button>
        </div>

    </div>
</template>

<script>

import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'

export default {
    props:['project'],
    components:{ImageBoxSelector},
    data:() => ({
        data:{
            translated:{},
        },
        currentStep:0,
    }),
    computed:{
        originalTranslated(){
            return this.project.seo && this.project.seo.translated || {}
        },
        translations(){
            let translations = this.project.seo && this.project.seo.translations || []
            let result = []
            translations.forEach(({children = [],...item}) => {

                if (item.type === 'header' && children.length){
                    result.push(item)
                    children.forEach(({children:grandKids = [],...childItem}) => {
                        result.push(childItem)
                        if (grandKids && grandKids.length){
                            grandKids.forEach(grandKid => {
                                result.push(grandKid)
                            })
                        }
                    })
                }else if (item.type !== 'header'){
                    result.push(item)
                }
            })
            return result
        },
        instance(){
            return this.$store.state.instance
        },
        theApp(){
            return this.$store.state.theApp
        },
    
    },
    methods:{
        update(){
            this.project.seo.translated = {...this.translated}
        }
    },
    created(){
        if (!this.project.seo) this.project.seo = {}
        if (!this.project.seo.translated) this.project.seo.translated = {}
        let translated = {...this.project.seo.translated} || {}
        if (this.translations && this.translations.length){
            this.translations.forEach(item => {
                if (item.type !== 'header' && !translated.hasOwnProperty(item.id) && item.hasOwnProperty('default')){
                    console.log('item.id ',item.id)
                    translated[item.id] = item.default
                }
            })
            this.translated = translated
        }
    }
}
</script>
