<template>
    <div class="bg-white px-3">
        <a-form-model ref="siteForm">

                <a-form-model-item label="Dark Logo" prop="darkLogo" class="empty mt-4">
                    <ImageBoxSelector v-model="project.data.darkLogo"/>
                </a-form-model-item>

                <a-form-model-item label="Light Logo" prop="lightLogo" class="empty mt-4">
                    <ImageBoxSelector v-model="project.data.lightLogo"/>
                </a-form-model-item>

        </a-form-model>
       
    </div>
</template>

<script>

import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'

export default {
    props:['project'],
    components:{ImageBoxSelector},
    data:() => ({
        data:{
            title:'',
            darkLogo:'',
            lightLogo:''
        },
        currentStep:0,

        rules: {
            title: [
                { required: true, message: `Please input your site's title`, trigger: 'blur' }
            ],
            darkLogo: [
                { required: true, message: 'Please provide your dark logo' }
            ],
            lightLogo: [
                { required: true, message: 'Please provide your light logo' }
            ],
        },
        onBoarding:false,
    }),
   
    computed:{
        instance(){
            return this.$store.state.instance
        },
        theApp(){
            return this.$store.state.theApp
        },
    
    },
    methods:{

    }
}
</script>
