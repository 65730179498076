<template >
    <div class="bg-white px-3" >

		<div class="title-second-color-picker" style="align-items:baseline; margin-top: 0; margin-bottom: 2rem;">
            <h4 class="pt-4 mb-0">Color Scheme</h4>
        </div>

        <a-form class="section-editor-container py-4 clr-gridd ">
            <a-form-item>
               <ColorPicker :defaults="project.colors._theme" :box-style="true" v-model="project.colors._theme[0]" :key="newTime" />
            </a-form-item>
            <a-form-item>
                <ColorPicker :defaults="project.colors._theme" :box-style="true" v-model="project.colors._theme[1]" :key="newTime" />
            </a-form-item>

            <a-form-item>
                <ColorPicker :defaults="project.colors._theme" :box-style="true" v-model="project.colors._theme[2]" :key="newTime" />
            </a-form-item>

            <a-form-item>
                <ColorPicker :defaults="project.colors._theme" :box-style="true" v-model="project.colors._theme[3]" :key="newTime" />
            </a-form-item>

            <a-form-item>
                <ColorPicker :defaults="project.colors._theme" :box-style="true" v-model="project.colors._theme[4]" :key="newTime"/>
            </a-form-item>

            <!-- <a-form-item :label="item.label" v-for="(item,itemI) in extraColors" :key="`extra${itemI}`">
                <ColorPicker :defaults="project.colors._theme" :box-style="true" v-model="project.colors[item.id]" :key="newTime"/>
            </a-form-item> -->

        </a-form>

        <div class="title-second-color-picker" style="align-items:baseline">
            <h4 class="mb-0">Suggested Color Schemes</h4>
            <a href="#" @click.prevent="generateColor" class="hover:text-primary text-sm" style="text-decoration:underline">Randomize Suggestionn</a>
        </div>

        <div class="colr-grid color-scheme-choices pb-8">
            <div class="scheme-choice" v-for="(item,itemI) in colorSuggestions" :key="itemI + 'color-choices'" @click="chooseColor(itemI)" :class="chosenSuggestion === itemI ? 'selected-color-suggestion' : ''">
                <span v-for="(colorItem,idx) in item" :key="idx + 'colorItem'" :style="`--color-here:${colorItem}`"></span>
            </div>
        </div>


    </div>
</template>

<script>
import ColorPicker from '@/components/site/ColorPicker'
var colorChoices = require('nice-color-palettes');
import {setProp} from 'bh-mod'

export default {
    props:['project'],
    components:{ColorPicker},
    data:() => ({
        newTime:Date.now(),
        colorSuggestions:[],
        chosenSuggestion:-1,
    }),
    watch:{
        'project.colors._theme'(val){
            console.log('THEME CHANGED')
            if (this.extraColors && this.extraColors.length){
                this.extraColors.forEach(item => {
                    if (item.default.includes('$$colors.')){
                        let defaultColor = item.default.replace('$$colors.','')

                        if (!defaultColor) return ''

                        let keys = ['secondaryColor','primaryColor','textColor','accentColor','buttonBackground']
                        let index = keys.findIndex(x => x === defaultColor)
                        if (index > -1){
                            setProp(this, ['project','colors',item.id], val[index])
                        }

                    }
                })
            }
        }
    },
    computed:{
        extraColors(){
            if (!this.project.settings.length && !this.project.settings.find(x => x.id === '$colors')) return []
            return this.project.settings.find(x => x.id === '$colors')?.children
        }
    },
    methods:{
        chooseColor(index){

                this.chosenSuggestion = index

                let colors = this.colorSuggestions[index]

                let chosenTheme = JSON.parse(JSON.stringify(colors))

                let keys = ['secondaryColor','primaryColor','textColor','accentColor','buttonBackground']

                keys.forEach((key,idx) => {
                    setProp(this, ['project','colors',key], colors[idx])
                })

                setProp(this, ['project','colors','primaryColor'], colors[1])
                setProp(this, ['project','colors','textColor'], '#888888')
                setProp(this, ['project','colors','buttonBackground'], colors[1])
                setProp(this, ['project','colors','buttonText'], '#FFFFFF')

                setProp(this, ['project','colors','_theme'], chosenTheme)

                this.newTime = Date.now()

        },
        generateColor(){

            let theColors = []
            for (let i = 0; i < 6;i++){
                let index = Math.ceil(Math.random() * 99)
                while(theColors.includes(index)) index = Math.ceil(Math.random() * 99)
                theColors.push(index)
            }
            this.colorSuggestions = theColors.map(idx => colorChoices[idx])
			console.log('COLOR SUGGESTIONS', this.colorSuggestions);

        }

    },
    created(){
        console.log('PROJECT SETTINGS', this.project)
        this.generateColor()
        // if (this.project.colors._theme){
        //     this.chosenTheme = JSON.parse(JSON.stringify(this.project.colors._theme))
        // }
    }
}
</script>

<style lang="scss">
    .clr-gridd{
        display:grid;
        grid-template-columns: repeat(2, 1fr);
        gap:1.5ch;

    }
    .title-second-color-picker{
        margin-top:30px;
        margin-bottom:3rem;
        @media screen and (min-width:1200px){
            margin-top:50px;
        }
    }
    .scheme-choice{
        display:flex;
        cursor:pointer;
        padding:5px;
        border:1px solid transparent;
        &.selected-color-suggestion{
            border:1px solid var(--secondary);
        }
        span{
            height:15px;
            flex:1;
            background:var(--color-here);
        }
    }
</style>
