<template >
    <div class=" bg-white px-3 section-card-component dF jSB aC" @mouseover="highlight" @mouseleave="unhighlight">

        <!-- <span class="section-card-tag" v-if="item.tag" v-text="item.tag" /> -->

        <div @click="$emit('edit')" class="dF aC py-3 f1 cursor-pointer hover:text-primary relative" style="gap:1ch;">
            <div v-if="svg" v-html="svg" style="height:13px" class="page-img-svg-holder"></div>
            <img v-else-if="image" :src="image" style="height:13px;" />
            <p class="mb-0 ">{{item.name ? item.name : item.tag ? item.tag : 'Section'}}</p>

            <!-- <a-popover
            v-if="!item.tag"
            :title="null"
            :visible="renameBool"
            @visibleChange="() => {}"
            >
                <div slot="content">
                    <a-input type="text" />
                    <a @click="$emit('rename',false)">Close</a>
                </div>

                <i class="fe fe-edit-2" @click.stop="$emit('rename-close')" />

            </a-popover> -->



        </div>


        <a-tooltip overlayClassName="change-tooltip-color" title="Drag to rearrange" :mouseEnterDelay="2">
            <a-icon class="handle" v-if="!item.tag" type="menu" style="font-size:11px" />
        </a-tooltip>
    </div>
</template>

<script>
export default {
    props:['item','renameSection'],
    data:() => ({
        renameBool:false,
    }),
    watch:{
        renameSection(val){
            if (val !== this.renameBool) this.renameBool = val
        },
        renameBool(val){
            this.$emit('renameClose')
        },
    },
    computed:{
        svg(){
            if (this.item && this.item.icon && this.item.icon.trim().indexOf('<svg') < 4){
                return this.item.icon
            }
            return false
        },
        image(){
            if (this.item.tag) return require(`@/assets/icons/${this.item.tag.toLowerCase()}.svg`)
            if (!this.item.icon) return require(`@/assets/icons/content.svg`)
            if (this.item.icon.indexOf('http') === 0 && this.item.icon.includes('://')){
                return this.item.icon
            }
            return require(`@/assets/icons/${this.item.icon || 'content'}.svg`)
        },
        siteURL(){
            return this.$store.state.appData.siteURL
        },
    },
    methods:{

        highlight(){
            if (document.querySelector('iframe') && this.item.id){
                document.querySelector('iframe').contentWindow.postMessage({action:'highlightSection',id:this.item.id},this.siteURL)
            }

        },
        unhighlight(){
            if (document.querySelector('iframe') && this.item.id){
                document.querySelector('iframe').contentWindow.postMessage({action:'unHighlightSection',id:this.item.id},this.siteURL)
            }
        }
    }
}
</script>

<style lang="scss">
    .section-card-component{
        border:1px solid var(--light-purple);
        border-left:0;
        border-right:0;
        position: relative;
        + .section-card-component{
            border-top:0;
        }
    }
    .handle{
        cursor:-moz-grab;
        cursor:-webkit-grab;
    }
    .section-card-tag{
        position: absolute;
        top:3px;
        left:20px;
        font-size:12px;
        margin-left:calc(20px + 1ch);
        color:var(--med-gray);
    }
    .page-img-svg-holder > svg{
        width:13px;
    }
</style>
