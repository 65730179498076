<template>
  <div v-if="obj.show" class="f1 relative">
            <a-form class="section-editor-container bg-white py-4" style="position:absolute; top:0;left:0;right:0;bottom:0;overflow:hidden;">
                <div style="position: absolute; padding-bottom: 100px; inset: 0px; overflow: hidden scroll;" class="mt-x3">
                    <slot />

                    <template v-for="(item,itemI) in obj.item.settings">
                        <template v-if="item.type != 'hidden'">
                            <h4 v-if="!item.type && item.label" :key="`section-${obj.item.id}-item-${itemI}`">
                                {{item.label}}
                            </h4>
                            <div v-else-if="item.type === 'header'" :key="`section-${obj.item.id}-item-${itemI}`" class="section-editor-grouped mb-3" :class="item.children && item.children.length ? 'section-editor-accordion-wrapper' : ''">
                                <h6 v-if="!item.children" v-text="item.label"/>
                                <a-collapse :defaultActiveKey="item.open ? '1' : '0'" v-else-if="item.children.length" expandIconPosition="right" style="border:0;">
                                    <a-collapse-panel key="1" :header="item.label" :style="'background: #f7f7f7;border-radius: 4px;;overflow: hidden;border:0;'">
                                        <template v-for="(childItem,childItemI) in item.children">
                                            <!-- <h6 v-if="!childItem.type && childItem.label" v-text="childItem.label" :key="`section-${obj.item.id}-item-${childItemI}${childItem.id}`" style="border-bottom:1px solid #ccc; padding-bottom:4px; padding-left:4px;" /> -->
                                            <template v-if="isShown[childItem.id] && childItem.id">
                                                <template v-if="childItem.type != 'hidden'">
                                                    <a-form-item :label="childItem.label" :key="`section-${obj.item.id}-item-${childItemI}${childItem.id}`" :help="showHelp(childItem,obj.item.data[childItem.id])">
                                                        <SectionEditItem :font-list="fontList" :key="`section-${obj.item.id}-item-${childItemI}${childItem.id}`" :data="obj.item.data" :setting="childItem"/>
                                                    </a-form-item>
                                                </template>
                                            </template>
                                        </template>
                                    </a-collapse-panel>
                                </a-collapse>

                            </div>
                            <template v-else-if="isShown[item.id]">
                                <a-form-item :label="item.label" :key="`section-${obj.item.id}-item-${itemI}`"  :help="showHelp(item)" class="px-3"  >
                                    <SectionEditItem :font-list="fontList"  :data="obj.item.data" :setting="item"/>
                                </a-form-item>
                            </template>
                        </template>

                    </template>
                    <BlocksEditor  :font-list="fontList"  v-if="obj.item.blocks && obj.item.blocks.blocks && obj.item.blocks.blocks.length" :item="obj.item" />


                </div>
            </a-form>

  </div>
</template>

<script>
import {setProp} from 'bh-mod'
import draggable from 'vuedraggable'
import SectionEditItem from '@/components/site/SectionEditItem'
import BlocksEditor from '@/components/site/BlocksEditor'

export default {
    props:['obj','fontList'],
    components:{
        draggable,
        SectionEditItem,
        BlocksEditor

    },
    watch:{
        obj(val){
            console.log('CHANGEDDDDDDD', obj)
        }
    },
    computed:{
        values(){
            let values = {}
            this.obj.item.settings.forEach( item => {
                values[item.id] = this.obj.item.data[item.id]

                if (item.children && item.children.length){
                    item.children.forEach(childItem => {
                        values[childItem.id] = this.obj.item.data[childItem.id]
                    })
                }
            })
            return values
        },
        isShown(){


            let isShown = {}


            this.obj.item.settings.forEach( item => {
                if (!item.id) return
                if (item.type === 'header' && !item.children) return
                else if (item.children && item.children.length){
                    item.children.forEach(childItem => {
                        let is = true
                        if (childItem.hasOwnProperty('onlyIf')){
                            let checkFor = true
                            let keyToCheck = childItem.onlyIf
                            if (keyToCheck.indexOf('!') === 0){
                                checkFor = false
                                keyToCheck = keyToCheck.substr(1)
                            }
                            is = Boolean(this.values[childItem.onlyIf]) === checkFor
                        }
                        isShown[childItem.id] = is
                    })
                }
                else isShown[item.id] = !item.onlyIf ?  true : Boolean(this.values[item.onlyIf])
            })
            return isShown
        }
    },
    methods:{
        showHelp(item,val){
            if (!val) return item.help || ''
            if (item.type === 'linkText') {
                if (val.indexOf('http') !== 0){
                    return 'Note: your link does not seem valid'
                } else if (!item.help){
                    return 'Enter a valid Link'
                }
            }
            if (item.help) return item.help
            return ''
        },
    }
}
</script>

<style>
    /* .section-editor-accordion-wrapper .ant-collapse-header{
        background:var(--grey-purple)
    } */
    /* .section-editor-accordion-wrapper .ant-collapse-content-box{
        background:#f9f9f9;
    } */
</style>
