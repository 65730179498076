<template>
    <div class="manage-section h-full dF fC">
        <div class="dF aC px-3 py-4" style="gap:1ch;" :class="editSectionObj.show && editSectionObj.item? 'bg-primary text-white' : ''">
            <template v-if="editSectionObj.show && editSectionObj.item">
                <!-- <i class="fa fa-times hover:text-primary" style="cursor:pointer" @click="editSectionObj.show = false"></i> -->
                <a-icon type="arrow-left" class="cursor-pointer" @click="$store.commit('SET_PROP', {where:['builder','currentSection'],what:''})" />
                <h6 class="mb-0" :class="editSectionObj.show && editSectionObj.item? 'text-white' : ''" >{{editSectionObj.item.id === project.id ? 'Common Elements' : editSectionObj.item.name}}</h6>
            </template>
            <h6 v-else class="mb-0" style="font-size: 1.15em;">Pages</h6>

        </div>
        <EditSection :fontList="fontList" v-if="editSectionObj.show" :obj="editSectionObj">

            <template v-if="!editSectionObj.item.pages">

                <template v-if="!editSectionObj.item.default">
                    <div class="mt-4 dF px-3" style="gap:1ch">
                        <a  href="#" @click.prevent="replaceSection()" class="aC gap-2 py-1 px-2 rounded-sm hover:text-black" style="display:inline-flex; gap:1ch; border:1px solid #ccc">
                            <i class="fe fe-refresh-ccw" /> Replace
                        </a>
                        <a-popover trigger="click"  placement="left" :auto-adjust-overflow="true" @visibleChange="saveSectionName = ''" :overlayStyle="{width:'350px'}">
                            <a-form slot="content">
                                <div>
                                    <p class="mb-1 text-primary" style="font-size:16px;">Save this section with current options. to reuse in other project designs. </p>
                                </div>
                                <a-form-item label="Name your Section">
                                    <a-input-search placeholder="Your custom Section" @search="saveSection" v-model="saveSectionName">
                                        <a-button slot="enterButton" type="primary" icon="check" />
                                    </a-input-search>
                                </a-form-item>
                            </a-form>
                            <a v-if="!editSectionObj.item.tag" href="#" class="aC gap-2 py-1 px-2 rounded-sm hover:text-black" style="display:inline-flex; gap:1ch; border:1px solid #ccc;">
                                <a-icon type="save" />
                            </a>
                        </a-popover>
                        <a v-if="!editSectionObj.item.tag" href="#" @click.prevent="removeSection(editSectionObj.item.id)" class="aC gap-2 py-1 px-2 rounded-sm hover:text-black" style="display:inline-flex; gap:1ch; border:1px solid #ccc; border-color:var(--danger); color:var(--danger)">
                            <a-icon type="minus-circle" /> Remove
                        </a>


                    </div>
                </template>
                <template v-else>
                    <div class="p-3 mb-3 bg-offwhite-dark bottom-section-toolbar">
                        <a-form-model>

                            <a-form-model-item label="Page Title" class="mb-3" v-if="editSectionObj && editSectionObj.item && editSectionObj.item.data && editSectionObj.item.data.hasOwnProperty('navTitle')">
                                <a-input v-model="editSectionObj.item.data.navTitle" />
                            </a-form-model-item>
                            <a-form-model-item label="Page Icon" class="mb-3" v-if="editSectionObj && editSectionObj.item && editSectionObj.item.data && editSectionObj.item.data.hasOwnProperty('navIcon')">
                                <IconSelect v-model="editSectionObj.item.data.navIcon" />
                            </a-form-model-item>
                            <a-form-model-item label="Page Visibility" class="mb-3" v-if="editSectionObj && editSectionObj.item && editSectionObj.item.data && editSectionObj.item.data.hasOwnProperty('show')">
                                <a-switch v-model="editSectionObj.item.data.show" />
                                <p v-if="!editSectionObj.item.data.show" class="text-danger mt-2" style="font-size:13px;line-height:1.2;">
                                    This section has been hidden from the Menu.
                                </p>
                            </a-form-model-item>
                            <a-form-model-item label="Choose an instance to pull data" class="mb-3" v-if="editSectionObj && editSectionObj.item && editSectionObj.item.data && editSectionObj.item.data.hasOwnProperty('instance') && hasSetting()">
                                <InstanceSelector v-model="editSectionObj.item.data.instance" />
                            </a-form-model-item>

							<a-form-model-item label="Email Subject" class="mb-3" v-if="editSectionObj && editSectionObj.item && editSectionObj.item.data && editSectionObj.item.data.hasOwnProperty('mailSubject')">
                                <a-input v-model="editSectionObj.item.data.mailSubject" />
                            </a-form-model-item>

							<a-form-model-item label="Choose an email template" class="mb-3" v-if="editSectionObj && editSectionObj.item && editSectionObj.item.data && editSectionObj.item.data.instance && editSectionObj.item.data.hasOwnProperty('mailTemplate')">
                                <EmailTemplateSelector v-model="editSectionObj.item.data.mailTemplate" :instance="editSectionObj.item.data.instance" />
                            </a-form-model-item>

							<a-form-model-item label="Choose an email sender" class="mb-3" v-if="editSectionObj && editSectionObj.item && editSectionObj.item.data && editSectionObj.item.data.instance && editSectionObj.item.data.hasOwnProperty('mailSender')">
                                <EmailSenderSelector v-model="editSectionObj.item.data.mailSender" :instance="editSectionObj.item.data.instance"/>
                            </a-form-model-item>

                        </a-form-model>

                    </div>
                </template>

            </template>

        </EditSection>


        <template v-else>
            <div class="mb-3">
                <SectionCard :item="navigation" @edit="editCommon(project)"  />
            </div>

            <!-- SECTIONS -->
            <div class="list-group mb-3">

                <draggable :list="sections" handle=".handle" :delay-on-touch-only="true" :delay="100">
                    <SectionCard  @rename-close="renameSection = false" @rename="renameSection = item.id" :rename-section="renameSection===item.sections[0].id" @edit="editSection(item.sections[0])" :item="item.sections[0]"  v-for="item in sections" :key="item.sections[0].id" />
                </draggable>

                <div @click="addSection" class="px-4 section-card-component dF aC jC text-primary cursor-pointer section-add-card" v-if="false">
                    <a-icon type="plus-circle" />
                    <p class="mb-0 py-3 cursor-pointer text-primary hover:text-primary">Add section</p>
                </div>

            </div>

            <!-- <div class="footer">
                <SectionCard v-for="item in getFooters" :key="item.id" :item="item" @edit="editSection(item)"  />
            </div> -->
        </template>
    </div>
</template>

<script>
import {setProp} from 'bh-mod'
import SectionCard from '@/components/site/SectionCard'
import IconSelect from '@/components/common/IconSelect'
import EditSection from '@/components/site/EditSection'
import draggable from 'vuedraggable'

export default {
    components:{
        draggable,SectionCard,EditSection,IconSelect,
        InstanceSelector: () => import('@/components/site/InstanceSelector'),
		EmailTemplateSelector: () => import('@/components/site/EmailTemplateSelector'),
		EmailSenderSelector: () => import('@/components/site/EmailSenderSelector')
    },
    props:['project','event','page'],
    data:()=>({
        navigation:{
            name:'Common Elements',
            tag:'header'
        },
        editSectionObj:{
            show:false,
            item:{}
        },
        saveSectionName:'',
        renameSection:'',
    }),
    watch:{
        event(val){

            if (typeof val === 'object'){

                if (val.action === 'sectionEdit'){

                    let id = val.id.replace('section-id-','')
                    let item = this.page.sections.find(x => x.id === id)
                    if (val.classList.includes('section-header') || val.classList.includes('section-footer')){
                        if (this.page.header.id === id)item = this.page.header
                        else item = this.page.footer
                        item.tag = this.page.header.id === item.id ? 'header' : 'footer'
                    }
                    console.log('ABOUT TO EDIT', item)
                    if (item){
                        this.editSection(item)
                    }

                }

            }
        },
        'editSectionObj.show'(val){
            if (!val){
                document.querySelector('iframe').contentWindow.postMessage({action:'unSelect'},this.siteURL)
            }
        },
        selectedID(val){
            console.log('SELECTEDID', val, this.project.id)
            if (val){



                let item = this.sections.find(x => x.id === val)
                if (val === this.project.id){
                    console.log('PROJECTTTTTT IDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD')
                    item = this.project
                } else if (!item){

                    item = this.sections.find(x => x.sections[0].id === val)
                    if (item){
                        item = item.sections[0]
                    }

                    if (this.getHeaders){
                        item = this.getHeaders.find(x => x.id === val)
                    } else if (this.getFooters){
                        item = this.getFooters.find(x => x.id === val)
                    }
                }



                if (!item) {

                    return this.editSectionObj.show = false
                }

                this.editSectionObj.show = true
                this.editSectionObj.item = item
                console.log('EDIT SECTION OBJ', this.editSectionObj)
                console.log('EDIT SECTION OBJ ITEM NAME', this.editSectionObj.item.name)
                if (val != this.project.id && this.editSectionObj.item.name != 'Masterplan'){
                    console.log('CONDITION RAN')
                    document.querySelector('iframe').contentWindow.postMessage({action:'selectedSection',slug:item.slug, id:item.id},this.siteURL)
                }

            } else {
                this.editSectionObj.show = false
            }
        }
    },
    computed:{
        selectedID(){
            return this.$store.state.appData.builder.currentSection
        },
        getHeaders(){
            if (this.page.header && this.page.header.id) return [{...this.page.header,tag:'Header'}]
            if (this.project.headers && this.project.headers.length) return this.project.headers.map(x => ({...x,tag:'Header'}))
            return false
        },
        getFooters(){
            if (this.page.footer && this.page.footer.id) return [{...this.page.footer,tag:'Footer'}]
            if (this.project.footers && this.project.footers.length) return this.project.footers.map(x => ({...x,tag:'Footer'}))
            return false
        },
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        },
        siteURL(){
            return this.$store.state.appData.siteURL
        },
        fontList(){
            return Object.values(this.project.fonts)
                .map( ({name,fontText}) => ({
                    label:name,
                    value:fontText
                }))
        },
        pages(){
            return this.project.pages || []
        },
        sections(){
            return this.pages
            // .map(x => {
            //     return x.sections && x.sections[0] || false
            // }).filter(x => x)
            // return this.page.sections || []
        }
    },
    methods:{
		hasSetting(){
			let find = this.editSectionObj.item.settings.find(x => x.id == 'instance')
			if (find) return true
			else return false
		},
        log(e){
            console.log('YOU SELECTED', e)
        },
        async saveSection(){
            let section = JSON.parse(JSON.stringify(this.editSectionObj.item))
            console.log('WANT TO SAVE THIS', section, 'width this name => :', this.saveSectionName)
            delete section.id
            delete section._id
            section.instance = this.$store.state.instance.id
            section.name = this.saveSectionName
			try {
				let { data } = await this.$api.post(`/sections/:instance`, section)
				this.$store.commit('SET_PROP', { where: ['sections', data.id], what: data })
				this.saveSectionName = ''
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			}
        },
        addSectionCallBack(item){
            if (!item.data) item.data = {}
            item.slug = 'W' + Date.now()
            item.id = "add_" + item.id
            if (item.settings && item.settings.length){
                let settings = JSON.parse(JSON.stringify(item.settings))
                settings.forEach( ({id,default:def}) => {
                    if (!item.data.hasOwnProperty(id)) item.data[id] = def
                })
            }
            this.page.sections.push(item)
            this.editSection(item)
        },
        replaceSectionCallback(item){
            item.id = this.editSectionObj.item.id
            let tag = 'NONENONE'
            if (this.editSectionObj.item.tag) tag = this.editSectionObj.item.tag.toLowerCase()
            if (this.page[tag]){
                item.tag = this.editSectionObj.item.tag
                setProp(this.page,[tag],item)
                setProp(this.editSectionObj,['item'],item)
            } else {
                let sections = this.sections.map(x => x.id === this.editSectionObj.item.id ? item : x )
                setProp(this.page,['sections'],sections)
                setProp(this.editSectionObj,['item'],item)
            }

            // this.editSection(item)
        },
        replaceSection(id){
            this.$store.commit('SET_PROP',{where:['sectionModal'],what:{init:true, show:true, cb:this.replaceSectionCallback, restriction:this.editSectionObj.item.restriction, replace:true }})
        },
        addSection(){
            this.$store.commit('SET_PROP',{where:['sectionModal'],what:{init:true, show:true, cb:this.addSectionCallBack, restriction:'none' }})
        },
        editCommon(item){
            console.log('EDITING SECTION', item)
			const slug = this.$store.state.instance.productType == 'lowrise' ? 'page-models' : 'page-suites'
            this.$store.commit('SET_PROP', {where:['builder','currentSection'],what:item.id})
            this.$store.commit('SET_PROP', {where:['builder','currentPageSlug'],what: slug})
        },
        editSection(item){
            console.log('EDITING SECTION', item)
            this.$store.commit('SET_PROP', {where:['builder','currentSection'],what:item.id})
            this.$store.commit('SET_PROP', {where:['builder','currentPageSlug'],what:item.slug})
        },
        removeSection(id){
            this.$confirm({
                title: "Delete",
                content: h => <div>Do you want to delete this Section?</div>,
                okText: 'DELETE',
                okType: 'danger',
                cancelText: 'CANCEL',
                centered: true,
                onOk:() => {
                    console.log('DELETING SECTION', id)
                    if (id.indexOf('add_') === -1) {
                        this.project.deletedSections.push(id)
                        console.log('ADDING DELETED SECTION to => this.project.deletedSections', this.project.deletedSections)
                    }
                    this.page.sections = this.page.sections.filter(x => x.id !== id)
                    this.$store.commit('SET_PROP', {where:['builder','currentSection'],what:''})
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        },
    }
}
</script>

<style lang="scss">
    .icon-library-preview-box{
        width:30px;height:30px;background:#eee; border:1px dashed #aaa; border-radius:10px;
        display:flex;
        justify-content: center;
        align-items: center;
        &:hover{
            border-color:var(--primary);
            cursor: pointer;
            box-shadow:1px 1px 4px rgba(100,100,100,0.3);
        }
        svg{
            flex:0 0 90%;
            max-height:95%;
        }
    }
    .bottom-section-toolbar{
        border-top:1px solid #ddd; border-bottom:1px solid #ddd
    }
    .section-editor-accordion-wrapper{
        +.section-editor-accordion-wrapper{
            border-top:1px solid var(--light-purple);
        }
    }
    .section-add-card{
        gap: 1ch;
        border-top: 0px;
        margin: 5px 5px;
        background: rgb(255, 255, 255);
        border-radius: 4px;
        border: 1px dashed currentColor;
        transition:background-color .3s ease-out;
        &:hover{
            background:#efebe6;
        }
    }
</style>
