<template>
    <a-modal
        :visible="dialog.show"
        @cancel="$store.commit('CLOSE_MODALS')"
        @ok="okColor"
        >
        <div style="display:flex;">
            <div style="display:flex; align-items:center">
                <div :style="{background:typeof color === 'string'? color : color.hex, width:'10px'}" />
                <Material v-model="color"  class="custom-slider" style="height:auto;"/>
            </div>
            <div style="padding:30px;">

                <Slider v-model="color" style="max-width:290px;margin-top:5px;"/>
                <div role="group" class="vc-slider-swatches" style="margin-top:10px;">
                <div @click="setColor('#FFFFFF')"  aria-label="color:#FFFF00" role="button" class="vc-slider-swatch"><div class="vc-slider-swatch-picker" :class="isActive('#FFFFFF')? 'vc-slider-swatch-picker--active' : ''" style="background: #ffffff; box-shadow:0 2px 5px rgba(100,100,100,0.3)"></div></div>
                <div @click="setColor('#DDDDDD')" aria-label="color:#FFFF00" role="button" class="vc-slider-swatch"><div class="vc-slider-swatch-picker" :class="isActive('#DDDDDD')? 'vc-slider-swatch-picker--active' : ''" style="background: #dddddd; box-shadow:0 2px 5px rgba(100,100,100,0.3)"></div></div>
                <div @click="setColor('#AAAAAA')"  aria-label="color:#FFFF00" role="button" class="vc-slider-swatch"><div class="vc-slider-swatch-picker" :class="isActive('#AAAAAA')? 'vc-slider-swatch-picker--active' : ''" style="background: #aaaaaa; box-shadow:0 2px 5px rgba(100,100,100,0.3)"></div></div>
                <div @click="setColor('#666666')"  aria-label="color:#FFFF00" role="button" class="vc-slider-swatch"><div class="vc-slider-swatch-picker" :class="isActive('#666666')? 'vc-slider-swatch-picker--active' : ''" style="background: #666666; box-shadow:0 2px 5px rgba(100,100,100,0.3)"></div></div>
                <div @click="setColor('#000000')"  aria-label="color:#FFFF00" role="button" class="vc-slider-swatch"><div class="vc-slider-swatch-picker" :class="isActive('#000000')? 'vc-slider-swatch-picker--active' : ''" style="background: #000000; box-shadow:0 2px 5px rgba(100,100,100,0.3)"></div></div>
                </div>

                <a-divider>Recent Colors</a-divider>

                <!-- <Compact v-model="color" /> -->

                <div role="group" class="vc-slider-swatches" style="margin-top:10px;">
                <div v-for="(clr,clrI) in recentColors" :key="`recentColor${clr}${clrI}`" @click="setColor(clr)"  :aria-label="`color:${clr}`" role="button" class="vc-slider-swatch"><div class="vc-slider-swatch-picker" :class="isActive(clr)? 'vc-slider-swatch-picker--active' : ''" :style="`background:${clr} ; box-shadow:0 2px 5px rgba(100,100,100,0.3)`"></div></div>
                </div>

            </div>
        </div>
    </a-modal>
</template>

<script>
import {Slider, Material,Compact} from 'vue-color'
export default {
    components:{
        Slider, Material,Compact
    },
    data(){
        return {
            color:'',
            rcnt:['#FFFFFF','#DDDDDD','#000000']
        }
    },
    watch:{
        dialog:{
            deep:true,
            async handler(val){
                // if (val) this.color = {hex:this.value}
                if (val.show) {
					try {
						this.color = val.color
						let { data } = await this.$api.get(`/emails/${this.instance.id}/${this.emailId}`)
						console.log('EMAILLLLLL ', data)
						this.$store.commit('SET_CSON', data.cson || [])
					} catch (err) {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					}
                }
            }
        }
    },
    computed:{
        cson(){
            return this.$store.state.appData.cson
        },
        emailId(){
            return this.$store.state.appData.templateDrawer.id
        },
        instance(){
            return this.$store.state.instance
        },
        dialog(){
            return this.$store.state.appData.chooseColor
        },
        recentColors(){
            let clrs =  this.cson || []
            clrs = clrs.filter( (x,xI) => xI < 10)
            if (clrs.length <= 7) clrs = [...clrs,...this.rcnt]
            return clrs
        }
    },
    methods:{
      setColor(color){
        this.color = {hex:color}
      },
      isActive(clr){

        let color = this.color
        if (typeof color !== 'string') color = color.hex

        color = color.toLowerCase().trim()
        clr = clr.toLowerCase().trim()

        return clr === color
      },
      async okColor(){

            let cson = this.cson || []
            cson = [...new Set([this.color.hex,...cson])]

            console.log('OPTIONS', cson)
			try {
				await this.$api.put(`/emails/${this.instance.id}/${this.emailId}`, { cson })
				this.$store.dispatch('MESSAGE', { action: 'applyColor', type: this.dialog.type, color: this.color })
				this.$store.commit('CLOSE_MODALS')
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			}
      }
    },
    created(){

    }
}
</script>

<style>

</style>
