<template>
  <div class="builder_section_modal-container relative" :class="dialog.show ? 'show' : ''">
    
    <div class="builder_section_modal-overlay" @click="close" />
    
    <div class="builder_section_modal relative">
        <BHLoading :show="loading" style="background:none !important" />
        <div class="builder_section_modal-wrapper" v-if="sections && sections.length">
            <div class="dF jSB px-3 pb-5" >
                <h4 class="mb-0 pl-3" v-if="dialog.restriction === 'none'">{{dialog.replace? 'Replace' : 'Add'}} Section</h4>
                <h4 class="mb-0 pl-3" v-else style="text-transform:capitalize">{{dialog.replace? 'Replace' : 'Add'}} {{dialog.restriction}}</h4>
                <a-icon type="close" />
            </div>
            <div class="builder_section-content-wrapper hide-scrollbar">
                <div class="scroll-y hide-scrollbar">
                    <ul class="section_cat-container">
                        <li class="section_cat-item" :class="filter === 'all' ? 'active' : ''" @click="filter='all'" >All Sections</li>
                        <li class="section_cat-item" :class="filter === '%%custom%%' ? 'active' : ''" @click="filter='%%custom%%'" >Saved Sections</li>
                        <li class="section_cat-item" :class="filter === cat.label ? 'active' : ''" @click="filter=cat.label" v-for="cat in sectionCategorized" :key="cat.label">{{cat.label}}</li>
                        <li v-if="sectionTransformed.uncat.length" class="section_cat-item" :class="filter === '%%uncat%%' ? 'active' : ''" @click="filter='%%uncat%%'" >Uncategorized</li>
                    </ul>
                </div>
                <div class="builder_section-grid-wrapper hide-scrollbar">
                    <div class="builder_section-grid" v-if="sectionsFiltered.length">
                        <div class="builder_section-grid-item" @click="selectSection(sec)" v-for="sec in sectionsFiltered" :key="sec.id" :style="`background-image: url(${sec.image})`" />
                    </div>
                    <div class="dF jC aC h-full" v-else>
                        <div class="text-center" v-if="filter == '%%custom%%'">
                            <a-icon style="font-size:30px;" type="info-circle" />
                            <p class=mt-2>No Saved Sections Found</p>
                        </div>
                        <div v-else  class="text-center">
                            <a-icon style="font-size:30px;" type="info-circle" />
                            <p class=mt-2>No Sections Found</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

  </div>
</template>

<script>
import BHLoading from 'bh-mod/components/common/Loading'
import axios from 'axios'
export default {
    props:[],
    components:{BHLoading},
    data:() => ({
        loading:false,
        filter:'all',
    }),
    watch:{
        'dialog.init':{
            async handler(val){

                if (!val) return 
                if (val){
    
                    this.loading = true 
                    
                    let {data} = await axios.post(`${this.$store.state.appData.siteURL}/list/sections`)
                    this.$store.commit('SET_SECTIONS', data)
                    this.loading = false 
                }

            }
        },
        'dialog.show':{
            async handler(val){
                if (val){
                    this.filter = 'all'
                    console.log('HELLO', val, this.$store.state.appData.sections)

                    
                }
            }
        },
        sections(val){
            if (val && val.length){

            }
        }
    },
    computed:{
        dialog(){
            return this.$store.state.appData.sectionModal
        },
        sections(){
            return Object.values(this.$store.state.appData.sections).filter(x => {
                return x.restriction === this.dialog.restriction
            })
        },
        instanceId(){
            return this.$store.state.instance.id
        },
        sectionCategorized(){
            return this.sectionTransformed.cats && Object.values(this.sectionTransformed.cats) || {}
        },
        customSections(){
            return this.sectionTransformed.custom || []
        },
        sectionTransformed(){
            let cats = {} 
            let custom = []
            let uncat = []

            this.sections.forEach(sec => {
                
                if (sec.instance === this.instanceId) return custom.push(sec)
                if (!sec.category) return uncat.push(sec)

                let cat = sec.category
                if (!cats[cat]) cats[cat] = {label:cat, sections:[]}
                cats[cat].sections.push(sec)
            })

            return {
                cats,
                custom,
                uncat
            }
        },
        sectionsFiltered(){
            if (!this.filter || this.filter === 'all') return this.sections
            if (this.filter === '%%custom%%') return this.customSections
            if (this.filter === '%%uncat%%') return this.sectionTransformed.uncat
            let result =  this.sectionTransformed.cats[this.filter] || {sections:[]}
            return result.sections

        }
    },
    methods:{
        selectSection(section){
            this.dialog.cb && this.dialog.cb(JSON.parse(JSON.stringify(section)))
            this.close()
        },
        close(){
            this.$store.commit('SET_PROP',{where:'sectionModal',what:{show:false,cb:null,init:true,restriction:'none',replace:false}})
        }
    },
    async created(){
       
    }
}
</script>

<style lang="scss">
    
    
    .builder_section_modal-overlay{
        position: absolute;
        left:0;
        bottom:0;
        top:0;
        right:0;
        background:rgba(100,100,100,0.4);
        z-index:100;
        
    }
    .builder_section_modal{
        position: absolute;
        z-index:101;
        max-width:calc(100% - 20px);
        max-height:calc(100% - 20px);
        top:50%;
        left:50%;
        transform: translate3d(-50%,-50%,0) scale(0.7);
        width:1200px;
        height:700px;
        background:white;
        box-shadow: 0px 2px 10px rgba(100,100,100,0.4);
        border-radius: 10px;
        transition:opacity .3s ease-out, transform .3s ease-out;
        
    }

   .builder_section_modal-container{
        position: fixed;
        left:0;
        top:0;
        width:100vw;
        height:100vh;
        z-index:100;
        &:not(.show){
            pointer-events: none;
            .builder_section_modal,.builder_section_modal-overlay{
                opacity:0;
                pointer-events: none;
            }
        }
        &.show{
            .builder_section_modal{
                // animation: scale-up .3s ease-out;
                // animation-fill-mode: forwards;
                transform: translate3d(-50%,-50%,0) scale(1);
                opacity:1;
            }
        }
    }

    @keyframes scale-up{
        to{
            transform: translate3d(-50%,-50%,0) scale(1);
        }
    }

    .builder_section_modal-wrapper{
        position: absolute;
        left:0;
        top:20px;
        width:100%;
        height:calc(100% - 60px);
        margin:0;
        overflow:hidden;
        display:flex;
        flex-direction: column;
        

    }
    .builder_section-content-wrapper{
        flex:1;
        display:grid;
        grid-template-columns: 150px 1fr;
        overflow-y:scroll;
    }
    .builder_section-grid-wrapper{
        position: relative;
        overflow-y:scroll;
    }
    .builder_section-grid{
        position: absolute;
        left:0;
        top:0;
        right:0;
        bottom:0;
        display:flex;
        flex-direction: column;
        gap:20px;
        padding:20px 20px 50px;
        @media screen and (min-width:900px){
            display:grid;
            padding:0 40px 40px;
            gap:40px;
            grid-template-columns: minmax(100px,1fr) minmax(100px,1fr);
        }
    }
    .builder_section-grid-item{
        height:0;
        padding-bottom:50%;
        background-size:95%;
        background-color:rgba(240,240,240,0.8);
        background-repeat:no-repeat;
        background-position: center;
        border-radius:4px;
        border:1px solid var(--light-purple);
        cursor: pointer;
        transition:transform .3s ease-out, box-shadow .3s ease-out;
        box-shadow:0 0px 1px rgba(100,100,100,0.1);
        &:hover{
            transform:translate3d(0,-1px,0);
        }
    }
    .section_cat-container{
        list-style:none;
        margin:0;
        padding:0 0 0 30px;

    }
    .section_cat-item{
        padding:5px 0;
        cursor:pointer;
        &:hover,&.active{
            color:var(--primary);
        }
    }
    .scroll-y{
        overflow-y:scroll;
    }
    
</style>